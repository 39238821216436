import Home from "./View/Home/Index";
import ContactUs from "./View/ContactUs/index";
import Privacy from "./View/ExtraPages/privacy";
import Terms from "./View/ExtraPages/terms";
import UGC from "./View/ExtraPages/ugc";

const routes = [
  { path: "/home", exact: true, name: "Home", component: Home },
  { path: "/contact-us", exact: true, name: "ContactUs", component: ContactUs },
  { path: "/privacy", name: "Privacy", component: Privacy },
  { path: "/terms", name: "Terms", component: Terms },
  { path: "/ugc", name: "UGC", component: UGC },
];
export default routes;
