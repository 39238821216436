import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import routes from "../route";
import Header from "../Defaults/Header";
import Footer from "../Defaults/Footer";

const Index = (props) => {
  return (
    <div>
      <Header />
      <Switch>
        {routes.map((route, index) => {
          return route.component ? (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props) => <route.component {...props} />}
            />
          ) : null;
        })}
        <Redirect to="/home" from="/" />
      </Switch>
      <Footer />
    </div>
  );
};
export default Index;