import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
              <p className="align-self-center">TWILER PORTIS</p>
            </div>
          </div>
          <div className="col-md-6 my-3 my-md-0">
            <div className="d-flex align-self-center justify-content-center">
              <Link to="terms">
                <p className="mx-3 text-uppercase">T&C</p>
              </Link>
              <Link to="privacy">
                <p className="mx-3 text-uppercase">Privacy Policy</p>
              </Link>
              <Link to="ugc">
                <p className="mx-3 text-uppercase">UGC</p>
              </Link>
              <Link to="/contact-us">
                <p className="mx-3 text-uppercase">contact us</p>
              </Link>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex align-self-center justify-content-center justify-content-md-end">
              <Link to="#">
                <i className="fab fa-facebook-square"></i>
              </Link>
              <Link to="#">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link to="#">
                <i className="fab fa-instagram"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
