import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "./Layout/index";

import "./App.css";

const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router>
      <Switch>
        <Route
          path="/"
          name="Layouts"
          render={(props) => <Layout {...props} />}
        />
      </Switch>
    </Router>
  );
};

export default App;
