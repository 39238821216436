import React from "react";

export default function Terms() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section>
        <div className="container">
          <h3 className="terms header text-center font-weight-bold">
            TERMS AND CONDITIONS OF USE
          </h3>
          <h4 className="font-weight-bold pt-4">Introduction</h4>
          <p>
            These terms and conditions apply between you, the User of this
            Website (including any sub-domains, unless expressly excluded by
            their own terms and conditions), and Twiler Portis, the owner and
            operator of this Website. Please read these terms and conditions
            carefully, as they affect your legal rights. Your agreement to
            comply with and be bound by these terms and conditions is deemed to
            occur upon your first use of the Website. If you do not agree to be
            bound by these terms and conditions, you should stop using the
            Website immediately.
          </p>
          <p>
            In these terms and conditions,
            <span className="font-weight-bold ml-1 mr-1"> User</span> or
            <span className="font-weight-bold ml-1 mr-1">Users</span> means any
            third party that accesses the Website and is not either (i) employed
            by Twiler Portis and acting in the course of their employment or
            (ii) engaged as a consultant or otherwise providing services to
            Twiler Portis and accessing the Website in connection with the
            provision of such services.
          </p>
          <p>
            You must be at least 18 years of age to use this Website. By using
            the Website and agreeing to these terms and conditions, you
            represent and warrant that you are at least 18 years of age.
          </p>
          <h4 className="font-weight-bold pt-3">
            Intellectual property and acceptable use
          </h4>
          <ol type="1">
            <li>
              All Content included on the Website, unless uploaded by Users, is
              the property of Twiler Portis, our affiliates or other relevant
              third parties. In these terms and conditions, Content means any
              text, graphics, images, audio, video, software, data compilations,
              page layout, underlying code and software and any other form of
              information capable of being stored in a computer that appears on
              or forms part of this Website, including any such content uploaded
              by Users. By continuing to use the Website you acknowledge that
              such Content is protected by copyright, trademarks, database
              rights and other intellectual property rights. Nothing on this
              site shall be construed as granting, by implication, estoppel, or
              otherwise, any license or right to use any trademark, logo or
              service mark displayed on the site without the owner's prior
              written permission
            </li>
            <li>
              You may, for your own personal, non-commercial use only, do the
              following:
            </li>
            <ol type="a" className="ml-5">
              <li>
                retrieve, display and view the Content on a computer screen
              </li>
            </ol>
            <li>
              You must not otherwise reproduce, modify, copy, distribute or use
              for commercial purposes any Content without the written permission
              of Twiler Portis.
            </li>

            <h4 className="font-weight-bold pt-5">Prohibited use</h4>
            <li>
              You may not use the Website for any of the following purposes:
            </li>
            <ol type="a" className="ml-3">
              <li className="ml-5">
                in any way which causes, or may cause, damage to the Website or
                interferes with any other person's use or enjoyment of the
                Website;
              </li>
              <li className="ml-5">
                in any way which is harmful, unlawful, illegal, abusive,
                harassing, threatening or otherwise objectionable or in breach
                of any applicable law, regulation, governmental order;
              </li>
              <li className="ml-5">
                making, transmitting or storing electronic copies of Content
                protected by copyright without the permission of the owner.
              </li>
            </ol>
            <h4 className="font-weight-bold pt-5">Links to other websites</h4>
            <li>
              This Website may contain links to other sites. Unless expressly
              stated, these sites are not under the control of Twiler Portis or
              that of our affiliates.
            </li>
            <li>
              We assume no responsibility for the content of such Websites and
              disclaim liability for any and all forms of loss or damage arising
              out of the use of them.
            </li>
            <li>
              The inclusion of a link to another site on this Website does not
              imply any endorsement of the sites themselves or of those in
              control of them.
            </li>
            <h4 className="font-weight-bold pt-4">
              Privacy Policy and Cookies Policy
            </h4>
            <li>
              Use of the Website is also governed by our Privacy Policy and
              Cookies Policy, which are incorporated into these terms and
              conditions by this reference. To view the Privacy Policy and
              Cookies Policy, please view on the site.
            </li>
            <h4 className="font-weight-bold pt-4">
              Availability of the Website and disclaimers
            </h4>
            <li>
              Any online facilities, tools, services or information that Twiler
              Portis makes available through the Website (the
              <span className="font-weight-bold ml-1 mr-1">Service</span>) is
              provided "as is" and on an "as available" basis. We give no
              warranty that the Service will be free of defects and/or faults.
              To the maximum extent permitted by the law, we provide no
              warranties (express or implied) of fitness for a particular
              purpose, accuracy of information, compatibility and satisfactory
              quality. Twiler Portis is under no obligation to update
              information on the Website.
            </li>
            <li>
              Whilst Twiler Portis uses reasonable endeavors to ensure that the
              Website is secure and free of errors, viruses and other malware,
              we give no warranty or guaranty in that regard and all Users take
              responsibility for their own security, that of their personal
              details and their computers.
            </li>
            <li>
              Twiler Portis accepts no liability for any disruption or
              non-availability of the Website.
            </li>
            <li>
              Twiler Portis reserves the right to alter, suspend or discontinue
              any part (or the whole of) the Website including, but not limited
              to, any products and/or services available. These terms and
              conditions shall continue to apply to any modified version of the
              Website unless it is expressly stated otherwise.
            </li>
            <h4 className="font-weight-bold pt-5">Limitation of liability</h4>
            <li>
              Nothing in these terms and conditions will: (a) limit or exclude
              our or your liability for death or personal injury resulting from
              our or your negligence, as applicable; (b) limit or exclude our or
              your liability for fraud or fraudulent misrepresentation; or (c)
              limit or exclude any of our or your liabilities in any way that is
              not permitted under applicable law.
            </li>
            <li>
              We will not be liable to you in respect of any losses arising out
              of events beyond our reasonable control.
            </li>
            <li>
              To the maximum extent permitted by law, Twiler Portis accepts no
              liability for any of the following:
            </li>
            <ol type="a" className="ml-3">
              <li className="ml-5">
                any business losses, such as loss of profits, income, revenue,
                anticipated savings, business, contracts, goodwill or commercial
                opportunities;
              </li>
              <li className="ml-5">
                loss or corruption of any data, database or software;
              </li>
              <li className="ml-5">
                any special, indirect or consequential loss or damage.
              </li>
            </ol>
            <h4 className="font-weight-bold pt-4">General</h4>
            <li>
              You may not transfer any of your rights under these terms and
              conditions to any other person. We may transfer our rights under
              these terms and conditions where we reasonably believe your rights
              will not be affected.
            </li>
            <li>
              These terms and conditions may be varied by us from time to time.
              Such revised terms will apply to the Website from the date of
              publication. Users should check the terms and conditions regularly
              to ensure familiarity with the then current version.
            </li>
            <li>
              These terms and conditions, together with the Privacy Policy and
              Cookies Policy, contain the whole agreement between the parties
              relating to its subject matter and supersede all prior
              discussions, arrangements or agreements that might have taken
              place in relation to the terms and conditions.
            </li>
            <li>
              The Contracts (Rights of Third Parties) Act 1999 shall not apply
              to these terms and conditions and no third party will have any
              right to enforce or rely on any provision of these terms and
              conditions.
            </li>
            <li>
              If any court or competent authority finds that any provision of
              these terms and conditions (or part of any provision) is invalid,
              illegal or unenforceable, that provision or part-provision will,
              to the extent required, be deemed to be deleted, and the validity
              and enforceability of the other provisions of these terms and
              conditions will not be affected.
            </li>
            <li>
              Unless otherwise agreed, no delay, act or omission by a party in
              exercising any right or remedy will be deemed a waiver of that, or
              any other, right or remedy.
            </li>
            <li>
              This Agreement shall be governed by and interpreted according to
              the law of England and Wales and all disputes arising under the
              Agreement (including non-contractual disputes or claims) shall be
              subject to the exclusive jurisdiction of the English and Welsh
              courts.
            </li>
            <h4 className="font-weight-bold pt-4">Twiler Portis details</h4>
            <li>
              Website
              <a href="https://www.twilerportis.app" className="ml-1 mr-1">
                https://www.twilerportis.app.
              </a>
              You can contact Twiler Portis by email on:
              <a
                href="mailto:tlcmillionairemom@gmail.com"
                className="ml-1 mr-1"
              >
                tlcmillionairemom@gmail.com
              </a>
            </li>
          </ol>
        </div>
      </section>
    </div>
  );
}
