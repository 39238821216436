import React from "react";
import FormBanner from "./FormBanner";
import DownloadBanner from "./DownloadBanner";
import PhImg1 from "../../Assets/phimg1.png";
import PhImg2 from "../../Assets/phimg2.png";
import PhImg3 from "../../Assets/phimg3.png";
import PageBreadCrumb from "../Components/pageBreadCrumb";

const Index = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const imagesArr = [
    {
      img: PhImg1,
    },
    {
      img: PhImg2,
    },
    {
      img: PhImg3,
    },
  ];
  return (
    <div>
      <section className="label">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-right">
              <h5>- HOME -</h5>
            </div>
          </div>
        </div>
      </section>
      <section>
        <DownloadBanner />
      </section>
      <PageBreadCrumb name="DISCOUNTED RATES FOR ANYONE WANTING AN APP BUILT" />
      <section>
        <FormBanner />
      </section>
      <PageBreadCrumb name="LIVE FULL, DIE EMPTY" />
      <section className="lower-banner">
        <div className="container">
          <div className="row px-3">
            {imagesArr.map((item, index) => {
              return (
                <div className="col-md-4 my-3 text-center" key={index}>
                  <img src={item.img} alt="img" />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Index;
