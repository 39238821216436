import React from "react";
export default function Privacy() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section>
        <div className="container">
          <h3 className="privacy-header text-center">PRIVACY POLICY</h3>
          <p className="mt-5">
            This privacy policy applies between you, the User of this Website
            and Twiler Portis, the owner and provider of this Website. Twiler
            Portis{" "}
            <a href="(www.twilerportis.app)" className="ml-1 mr-1">
              (www.twilerportis.app)
            </a>
            takes the privacy of your information very seriously. This privacy
            policy applies to our use of any and all Data collected by us or
            provided by you in relation to your use of the Website.
            <br />
            This privacy policy should be read alongside, and in addition to,
            our Terms and Conditions, which can be found at:
            <a href="(www.twilerportis.app)" className="ml-1 mr-1">
              (www.twilerportis.app)
            </a>
          </p>

          <h6 className="privacy-read">
            Please read this privacy policy carefully.
          </h6>
          <h5 className="mt-5 definition-para">
            Definitions and interpretation
          </h5>
          <ol type="1" className="ml-3 mt-3">
            <li>In this privacy policy, the following definitions are used:</li>

            <table border="1" width="98%" height="60" className="mt-3 ml-4">
              <tr>
                <td className="text-left font-weight-bold table-data">Data</td>
                <td className="text-left table-data">
                  collectively all information that you submit to Twiler Portis
                  via the Website. This definition incorporates, where
                  applicable, the definitions provided in the Data Protection
                  Laws;
                </td>
              </tr>
              <tr>
                <td className="text-left font-weight-bold">Cookies</td>
                <td className="text-left">
                  a small text file placed on your computer by this Website when
                  you visit certain parts of the Website and/or when you use
                  certain features of the Website. Details of the cookies used
                  by this Website are set out in the clause below
                  <span className="font-weight-bold"> (Cookies)</span>;
                </td>
              </tr>
              <tr>
                <td className="text-left font-weight-bold table-data">
                  Data Protection Laws
                </td>
                <td className="text-left table-data">
                  any applicable law relating to the processing of personal
                  Data, including but not limited to the Directive 96/46/EC
                  (Data Protection Directive) or the GDPR, and any national
                  implementing laws, regulations and secondary legislation, for
                  as long as the GDPR is effective in the UK;
                </td>
              </tr>
              <tr>
                <td className="text-left font-weight-bold">GDPR</td>
                <td className="text-left">
                  the General Data Protection Regulation (EU) 2016/679;
                </td>
              </tr>
              <tr>
                <td className="text-left font-weight-bold">
                  UK and EU Cookie Law
                </td>
                <td className="text-left">
                  the Privacy and Electronic Communications (EC Directive)
                  Regulations 2003 as amended by the Privacy and Electronic
                  Communications (EC Directive) (Amendment) Regulations 2011;
                </td>
              </tr>
              <tr>
                <td className="text-left font-weight-bold table-data">
                  User or you
                </td>
                <td className="text-left table-data">
                  any third party that accesses the Website and is not either
                  (i) employed by Twiler Portis and acting in the course of
                  their employment or (ii) engaged as a consultant or otherwise
                  providing services to Twiler Portis and accessing the Website
                  in connection with the provision of such services; and
                </td>
              </tr>
              <tr>
                <td className="text-left font-weight-bold">Website</td>
                <td className="text-left">
                  the website that you are currently using,
                  <a href="www.twilerportis.app" className="ml-1 mr-1">
                    www.twilerportis.app
                  </a>{" "}
                  and any sub-domains of this site unless expressly excluded by
                  their own terms and conditions.
                </td>
              </tr>
            </table>
            <li className="mt-3 ml-4">
              In this privacy policy, unless the context requires a different
              interpretation:
            </li>
            <ol type="a" className="ml-5">
              <li>the singular includes the plural and vice versa;</li>
              <li>
                references to sub-clauses, clauses, schedules or appendices are
                to sub-clauses, clauses, schedules or appendices of this privacy
                policy;
              </li>
              <li>
                a reference to a person includes firms, companies, government
                entities, trusts and partnerships;
              </li>
              <li>
                "including" is understood to mean "including without
                limitation";
              </li>
              <li>
                reference to any statutory provision includes any modification
                or amendment of it;
              </li>
              <li>
                the headings and sub-headings do not form part of this privacy
                policy.
              </li>
            </ol>
            <h6 className="font-weight-bold pt-4">
              Scope of this privacy policy
            </h6>
            <li>
              This privacy policy applies only to the actions of Twiler Portis
              and Users with respect to this Website. It does not extend to any
              websites that can be accessed from this Website including, but not
              limited to, any links we may provide to social media websites.
            </li>
            <li>
              For purposes of the applicable Data Protection Laws, Twiler Portis
              is the "data controller". This means that Twiler Portis determines
              the purposes for which, and the manner in which, your Data is
              processed.
            </li>
            <h6 className="font-weight-bold pt-5">Data collected</h6>
            <li>
              We may collect the following Data, which includes personal Data,
              from you:
            </li>
            <ol type="a" className="ml-5">
              <li>name;</li>
              <li>date of birth;</li>
              <li>gender;</li>
              <li>job title;</li>
              <li>profession;</li>
              <li>
                contact Information such as email addresses and telephone
                numbers;
              </li>
              <li>
                demographic information such as postcode, preferences and
                interests;
              </li>
              <li>
                financial information such as credit / debit card numbers;
              </li>
              <li>IP address (automatically collected);</li>
              <li>web browser type and version (automatically collected);</li>
              <li>operating system (automatically collected);</li>
              <li>
                a list of URLs starting with a referring site, your activity on
                this Website, and the site you exit to (automatically
                collected);
              </li>
              <li>information relating to your business;</li>
            </ol>
            <p className="ml-5">
              in each case, in accordance with this privacy policy.
            </p>
            <h6 className="font-weight-bold pt-5 ">How we collect Data</h6>
            <li>We collect Data in the following ways:</li>
            <ol type="a" className="ml-5">
              <li>data is given to us by you;</li>
              <li>data is received from other sources; and;</li>
              <li>data is collected automatically.</li>
            </ol>
            <h6 className="font-weight-bold pt-5 ">
              Data that is given to us by you
            </h6>
            <li>
              Twiler Portis will collect your Data in a number of ways, for
              example:
            </li>
            <ol type="a" className="ml-5">
              <li>
                when you contact us through the Website, by telephone, post,
                e-mail or through any other means;
              </li>
              <li>
                when you register with us and set up an account to receive our
                products/services;
              </li>
              <li>
                when you complete surveys that we use for research purposes
                (although you are not obliged to respond to them);
              </li>
              <li>
                when you enter a competition or promotion through a social media
                channel;
              </li>
              <li>
                when you make payments to us, through this Website or otherwise;
              </li>
              <li>
                when you elect to receive marketing communications from us;
              </li>
              <li>when you use our services;</li>
              <li>from media or other information public sources;</li>
            </ol>
            <p className="ml-5 pt-2">
              in each case, in accordance with this privacy policy.
            </p>
            <h6 className="font-weight-bold pt-5 ">
              Data that is received from third parties
            </h6>
            <li>
              Twiler Portis will receive Data about you from the following third
              parties:
            </li>
            <ol type="a" className="ml-5">
              <li>service providers.</li>
            </ol>
            <h6 className="font-weight-bold pt-4">
              Data that is received from publicly available third parties
              sources
            </h6>
            <li>
              We will receive Data about you from the following publicly
              available third party sources:
            </li>
            <ol type="a" className="ml-5">
              <li>Companies House or Company registered details.</li>
            </ol>
            <h6 className="font-weight-bold pt-4">
              Data that is collected automatically
            </h6>
            <li>
              To the extent that you access the Website, we will collect your
              Data automatically, for example:
            </li>
            <ol type="a" className="ml-5">
              <li>
                we automatically collect some information about your visit to
                the Website. This information helps us to make improvements to
                Website content and navigation, and includes your IP address,
                the date, times and frequency with which you access the Website
                and the way you use and interact with its content.
              </li>
              <li>
                we will collect your Data automatically via cookies, in line
                with the cookie settings on your browser. For more information
                about cookies, and how we use them on the Website, see the
                section below, headed "Cookies".
              </li>
            </ol>
            <h6 className="font-weight-bold pt-5">Our use of Data</h6>
            <li>
              Any or all of the above Data may be required by us from time to
              time in order to provide you with the best possible service and
              experience when using our Website. Specifically, Data may be used
              by us for the following reasons:
            </li>

            <ol type="a" className="ml-5">
              <li>internal record keeping;</li>
              <li>internal research and development purposes;</li>
              <li>improvement of our products / services;</li>
              <li>
                personalization of content, business information or user
                experience;
              </li>
              <li>delivering marketing and events communication;</li>
              <li>
                transmission by email of marketing materials that may be of
                interest to you;
              </li>
              <li>
                contact for market research purposes which may be done using
                email, telephone, fax or mail. Such information may be used to
                customize or update the Website;
              </li>
              <li>legal obligations (such as prevention of fraud)</li>
            </ol>
            <p className="ml-5">
              in each case, in accordance with this privacy policy.
            </p>
            <li>
              We may use your Data for the above purposes if we deem it
              necessary to do so for our legitimate interests. If you are not
              satisfied with this, you have the right to object in certain
              circumstances (see the section headed "Your rights" below).
            </li>
            <li>
              For the delivery of direct marketing to you via e-mail, we'll need
              your consent, whether via an opt-in or soft-opt-in:
            </li>
            <ol type="a" className="ml-5">
              <li>
                soft opt-in consent is a specific type of consent which applies
                when you have previously engaged with us (for example, you
                contact us to ask us for more details about a particular
                product/service, and we are marketing similar
                products/services). Under "soft opt-in" consent, we will take
                your consent as given unless you opt-out.
              </li>
              <li>
                for other types of e-marketing, we are required to obtain your
                explicit consent; that is, you need to take positive and
                affirmative action when consenting by, for example, checking a
                tick box that we'll provide.
              </li>
              <li>
                if you are not satisfied about our approach to marketing, you
                have the right to withdraw consent at any time. To find out how
                to withdraw your consent, see the section headed "Your rights"
                below.
              </li>
            </ol>
            <li>
              When you register with us and set up an account to receive our
              services, the legal basis for this processing is the performance
              of a contract between you and us and/or taking steps, at your
              request, to enter into such a contract.
            </li>
            <li>
              We may use your Data to show you Twiler Portis adverts and other
              content on other websites. If you do not want us to use your data
              to show you Twiler Portis adverts and other content on other
              websites, please turn off the relevant cookies (please refer to
              the section headed "Cookies" below).
            </li>
            <h6 className="font-weight-bold pt-5">Who we share Data with</h6>
            <li>
              We may share your Data with the following groups of people for the
              following reasons:
            </li>
            <ol className="ml-5" type="a">
              <li>
                any of our group companies or affiliates - to support the use of
                value added services. to support integration of services with
                affiliates. to ensure that services provided are managed
                correctly if services chosen are from associated company or
                affiliates to ensure the proper administration of your account
                and business services provided by us;
              </li>
              <li>
                our employees, agents and/or professional advisors - to provide
                Customer Support Requests to complete changes or update records;
              </li>
              <li>
                third party service providers who provide services to us which
                require the processing of personal data - to ensure service
                levels are achieved and to ensure our website and service
                provision is maintained.;
              </li>
              <li>
                third party payment providers who process payments made over the
                Website - to enable third party payment providers to process
                user payments and refunds;
              </li>
              <li>
                relevant authorities - to facilitate the detection of crime or
                the collection of taxes or duties;
              </li>
            </ol>
            <p className="ml-5">
              in each case, in accordance with this privacy policy.
            </p>
            <h6 className="font-weight-bold">Keeping Data secure</h6>
            <li>
              We will use technical and organisational measures to safeguard
              your Data, for example:
            </li>
            <ol type="a" className="ml-5">
              <li>
                access to your account is controlled by a password and a user
                name that is unique to you.
              </li>
              <li>we store your Data on secure servers.</li>

              <li>
                payment details are encrypted using SSL technology (typically
                you will see a lock icon or green address bar (or both) in your
                browser when we use this technology.
              </li>
            </ol>
            <li>These measures are in place to:</li>
            <ol type="a" className="ml-5">
              <li>Protect data against accidental loss of data;</li>
              <li>
                Prevent unauthorized access, use, destruction or disclosure of
                data;
              </li>
              <li>
                To conduct privacy impact assessments in accordance with the law
                and your business policies;
              </li>
              <li>To manage third party risks</li>
            </ol>
            <li>
              Technical and organisational measures include measures to deal
              with any suspected data breach. If you suspect any misuse or loss
              or unauthorised access to your Data, please let us know
              immediately by contacting us via this e-mail address:
              <a
                href="mailto:tlcmillionairemom@gmail.com"
                className="ml-1 mr-1"
              >
                tlcmillionairemom@gmail.com
              </a>
            </li>
            <li>
              If you want detailed information from Get Safe Online on how to
              protect your information and your computers and devices against
              fraud, identity theft, viruses and many other online problems,
              please visit www.getsafeonline.org. Get Safe Online is supported
              by HM Government and leading businesses.
            </li>
            <h6 className="font-weight-bold pt-4">Data retention</h6>
            <li>
              Unless a longer retention period is required or permitted by law,
              we will only hold your Data on our systems for the period
              necessary to fulfil the purposes outlined in this privacy policy
              or until you request that the Data be deleted.
            </li>
            <li>
              Even if we delete your Data, it may persist on backup or archival
              media for legal, tax or regulatory purposes.
            </li>
            <h6 className="font-weight-bold pt-4">Your rights</h6>
            <li>You have the following rights in relation to your Data:</li>
            <ol type="a" className="ml-5">
              <li>
                <span className="font-weight-bold">Right to access</span> - the
                right to request (i) copies of the information we hold about you
                at any time, or (ii) that we modify, update or delete such
                information. If we provide you with access to the information we
                hold about you, we will not charge you for this, unless your
                request is "manifestly unfounded or excessive." Where we are
                legally permitted to do so, we may refuse your request. If we
                refuse your request, we will tell you the reasons why.
              </li>
              <li>
                <span className="font-weight-bold">Right to correct</span> - the
                right to have your Data rectified if it is inaccurate or
                incomplete.
              </li>
              <li>
                <span className="font-weight-bold">Right to erase </span>- the
                right to request that we delete or remove your Data from our
                systems. Where we are legally permitted to do so, we may refuse
                your request. If we refuse your request, we will tell you the
                reasons why.
              </li>
              <li>
                <span className="font-weight-bold">
                  Right to restrict our use of your Data
                </span>
                - the right to "block" us from using your Data or limit the way
                in which we can use it.
              </li>
              <li>
                <span className="font-weight-bold">
                  Right to data portability
                </span>
                - the right to request that we move, copy or transfer your Data.
              </li>
              <li>
                <span className="font-weight-bold">Right to object</span> - the
                right to object to our use of your Data including where we use
                it for our legitimate interests.
              </li>
              <li>
                <span className="font-weight-bold">
                  Right to withdraw consent
                </span>
                - if data was processed on the basis of consent
              </li>
              <li>
                Lodging a complaint with the Information Commissioner’s Office
              </li>
            </ol>
            <li>
              To make enquiries, exercise any of your rights set out above, or
              withdraw your consent to the processing of your Data (where
              consent is our legal basis for processing your Data), please
              contact us via this e-mail address:
              <a
                href="mailto:tlcmillionairemom@gmail.com"
                className="ml-1 mr-1"
              >
                tlcmillionairemom@gmail.com
              </a>
            </li>
            <li>
              If you are not satisfied with the way a complaint you make in
              relation to your Data is handled by us, you may be able to refer
              your complaint to the relevant data protection authority. For the
              UK, this is the Information Commissioner's Office (ICO). The ICO's
              contact details can be found on their website at
              https://ico.org.uk/.
            </li>
            <li>
              It is important that the Data we hold about you is accurate and
              current. Please keep us informed if your Data changes during the
              period for which we hold it.
            </li>
            <h6 className="font-weight-bold pt-4">
              Transfers outside the European Economic Area
            </h6>
            <li>
              Data which we collect from you may be stored and processed in and
              transferred to countries outside of the European Economic Area
              (EEA). For example, this could occur if our servers are located in
              a country outside the EEA or one of our service providers is
              situated in a country outside the EEA. We also share information
              with our group companies, some of which are located outside the
              EEA.
            </li>
            <li>
              We will only transfer Data outside the EEA where it is compliant
              with data protection legislation and the means of transfer
              provides adequate safeguards in relation to your data, eg by way
              of data transfer agreement, incorporating the current standard
              contractual clauses adopted by the European Commission, or by
              signing up to the EU-US Privacy Shield Framework, in the event
              that the organisation in receipt of the Data is based in the
              United States of America.
            </li>
            <li>
              To ensure that your Data receives an adequate level of protection,
              we have put in place appropriate safeguards and procedures with
              the third parties we share your Data with. This ensures your Data
              is treated by those third parties in a way that is consistent with
              the Data Protection Laws.
            </li>
            <h6 className="font-weight-bold pt-4">Links to other websites</h6>
            <li>
              This Website may, from time to time, provide links to other
              websites. We have no control over such websites and are not
              responsible for the content of these websites. This privacy policy
              does not extend to your use of such websites. You are advised to
              read the privacy policy or statement of other websites prior to
              using them.
            </li>
            <h6 className="font-weight-bold pt-4">
              Changes of business ownership and control
            </h6>
            <li>
              Twiler Portis may, from time to time, expand or reduce our
              business and this may involve the sale and/or the transfer of
              control of all or part of Twiler Portis. Data provided by Users
              will, where it is relevant to any part of our business so
              transferred, be transferred along with that part and the new owner
              or newly controlling party will, under the terms of this privacy
              policy, be permitted to use the Data for the purposes for which it
              was originally supplied to us.
            </li>
            <li>
              We may also disclose Data to a prospective purchaser of our
              business or any part of it.
            </li>
            <li>
              In the above instances, we will take steps with the aim of
              ensuring your privacy is protected.
            </li>
            <h6 className="font-weight-bold pt-4">Cookies</h6>
            <li>
              This Website may place and access certain Cookies on your
              computer. Twiler Portis uses Cookies to improve your experience of
              using the Website and to improve our range of products and
              services. Twiler Portis has carefully chosen these Cookies and has
              taken steps to ensure that your privacy is protected and respected
              at all times.
            </li>
            <li>
              All Cookies used by this Website are used in accordance with
              current UK and EU Cookie Law.
            </li>
            <li>
              Before the Website places Cookies on your computer, you will be
              presented with a message bar requesting your consent to set those
              Cookies. By giving your consent to the placing of Cookies, you are
              enabling Twiler Portis to provide a better experience and service
              to you. You may, if you wish, deny consent to the placing of
              Cookies; however certain features of the Website may not function
              fully or as intended.
            </li>
            <li>This Website may place the following Cookies:</li>
            <table border="1" width="98%" height="auto" className="mt-3 ml-4">
              <tr>
                <th>Type of Cookie</th>
                <th>Purpose</th>
              </tr>
              <tr>
                <td className="text-left">Strictly necessary cookies</td>
                <td className="text-left">
                  These are cookies that are required for the operation of our
                  website. They include, for example, cookies that enable you to
                  log into secure areas of our website, use a shopping cart or
                  make use of e-billing services.
                </td>
              </tr>
              <tr>
                <td className="text-left">Analytical/performance cookies</td>
                <td className="text-left">
                  They allow us to recognise and count the number of visitors
                  and to see how visitors move around our website when they are
                  using it. This helps us to improve the way our website works,
                  for example, by ensuring that users are finding what they are
                  looking for easily.
                </td>
              </tr>
              <tr>
                <td className="text-left">Functionality cookies</td>
                <td className="text-left">
                  These are used to recognise you when you return to our
                  website. This enables us to personalise our content for you,
                  greet you by name and remember your preferences (for example,
                  your choice of language or region).
                </td>
              </tr>
              <tr>
                <td className="text-left">Targeting cookies</td>
                <td className="text-left">
                  These cookies record your visit to our website, the pages you
                  have visited and the links you have followed. We will use this
                  information to make our website and the advertising displayed
                  on it more relevant to your interests. We may also share this
                  information with third parties for this purpose.
                </td>
              </tr>
            </table>
            <li className=" mt-3">
              You can find a list of Cookies that we use in the Cookies
              Schedule.
            </li>
            <li>
              You can choose to enable or disable Cookies in your internet
              browser. By default, most internet browsers accept Cookies but
              this can be changed. For further details, please consult the help
              menu in your internet browser.
            </li>
            <li>
              You can choose to delete Cookies at any time; however, you may
              lose any information that enables you to access the Website more
              quickly and efficiently including, but not limited to,
              personalization settings.
            </li>
            <li>
              It is recommended that you ensure that your internet browser is
              up-to-date and that you consult the help and guidance provided by
              the developer of your internet browser if you are unsure about
              adjusting your privacy settings.
            </li>
            <li>
              For more information generally on cookies, including how to
              disable them, please refer to aboutcookies.org. You will also find
              details on how to delete cookies from your computer.
            </li>
            <h6 className="font-weight-bold pt-4">General</h6>
            <li>
              You may not transfer any of your rights under this privacy policy
              to any other person. We may transfer our rights under this privacy
              policy where we reasonably believe your rights will not be
              affected.
            </li>
            <li>
              If any court or competent authority finds that any provision of
              this privacy policy (or part of any provision) is invalid, illegal
              or unenforceable, that provision or part-provision will, to the
              extent required, be deemed to be deleted, and the validity and
              enforceability of the other provisions of this privacy policy will
              not be affected.
            </li>
            <li>
              Unless otherwise agreed, no delay, act or omission by a party in
              exercising any right or remedy will be deemed a waiver of that, or
              any other, right or remedy.
            </li>
            <li>
              This Agreement will be governed by and interpreted according to
              the law of England and Wales. All disputes arising under the
              Agreement will be subject to the exclusive jurisdiction of the
              English and Welsh courts.
            </li>
            <h6 className="font-weight-bold pt-4">
              Changes to this privacy policy
            </h6>
            <li>
              Twiler Portis reserves the right to change this privacy policy as
              we may deem necessary from time to time or as may be required by
              law. Any changes will be immediately posted on the Website and you
              are deemed to have accepted the terms of the privacy policy on
              your first use of the Website following the alterations.
            </li>
            <li>
              You may contact Twiler Portis by email at:
              <a href="mailto:tlcmillionairemom@gmail.com" className="ml-1">
                tlcmillionairemom@gmail.com
              </a>
            </li>

            <h6 className="font-weight-bold pt-4">Cookies</h6>
            <p className="ml-2">
              Below is a list of the cookies that we use. We have tried to
              ensure this is complete and up to date, but if you think that we
              have missed a cookie or there is any discrepancy, please let us
              know.
            </p>
          </ol>
          <ins className="ml-3">Strictly necessary</ins>
          <p className="ml-3 pt-2">
            We use the following strictly necessary cookies:
          </p>
          <ol type="a">
            <li className="cookies-1">
              We use this session cookie to remember you and maintain your
              session whilst you are using our website
            </li>
          </ol>
          <ins className="ml-3">Analytical/performance</ins>
          <p className="ml-3 pt-2">
            We use the following analytical/performance cookies:
          </p>
          <ol type="a">
            <li className="cookies-2">
              We use this session cookie to remember you and maintain your
              session whilst you are using our website
            </li>
            <li className="cookies-3">
              We use this cookie to help us analyse how users use the website
            </li>
          </ol>
          <ins className="ml-3">Functionality</ins>
          <p className="ml-3 pt-2">
            We use the following functionality cookies:
          </p>
          <ol type="a">
            <li className="cookies-4">
              We use this cookie to identify your computer and analyse traffic
              patterns on our website
            </li>

            <ins className="ml-3">Targeting</ins>
            <p className="ml-3 pt-2">We use the following targeting cookies:</p>

            <li className="cookies-5">
              We use this cookie to enable us to show you adverts while you are
              browsing our website and other websites on the internet.
            </li>
          </ol>
        </div>
      </section>
    </div>
  );
}
