import React from "react";
import { Link } from "react-router-dom";
import BanImg from "../../Assets/ScanPic.jpeg";

const FormBanner = () => {
  return (
    <div className="banner">
      <div className="container">
        <div className="row px-3">
          <div className="col-md-4 ml-auto my-4">
            <img src={BanImg} alt="img" />
            <p className="mt-4">
              Join Twiler Portis and get your own mobile APP built
            </p>
            <p>
              It will supercharge your business revenues, grow user engagement
              and build loyalty programs.
            </p>
            <p>DESIGN IT. BUILD IT. SHARE IT.</p>
            <Link to="/#">
              <p>Register here:</p>
            </Link>
          </div>
          <div className="col-md-8 mx-auto my-4">
            <h4>More freedom today</h4>
            <h2>
              ARE YOU READY TO <br />
              BUILD YOUR OWN APP?
            </h2>

            {/* form area */}
            <div className="form_bgNew" style={{ maxWidth: "450px" }}>
              <div className="form-group m-2 mt-4">
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  placeholder="Name"
                />
              </div>
              <div className="form-group m-2">
                <input
                  type="text"
                  className="form-control"
                  id="inputNumber"
                  placeholder="Number"
                />
              </div>
              <div className="form-group m-2">
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail4"
                  placeholder="Email"
                />
              </div>
              <div className="form-group m-2">
                <input
                  type="text"
                  className="form-control"
                  id="inputCompany"
                  placeholder="Company"
                />
              </div>
              <div className="form-group m-2">
                <textarea
                  className="form-control"
                  id="text"
                  rows={4}
                  placeholder="Please register my interest in building my own business APP."
                ></textarea>
              </div>
              <div className="text-right m-2">
                <button className="btn submit_btn" type="button">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormBanner;
