import React from "react";

const PageBreadCrumb = (props) => {
  return (
    <section className="pink_box">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h5>{props.name}</h5>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PageBreadCrumb;
