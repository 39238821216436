import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/logo.png";
import Logo2 from "../Assets/logo2.PNG";

const Header = () => {
  return (
    <div className="header py-2 py-md-4">
      <div className="container p-md-0">
        <nav className="navbar navbar-expand-lg text-white navbar-dark p-0">
          <Link className="navbar-brand" to="/">
            <img src={Logo} alt="img" className="logo1" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="navbar-nav m-auto flex-column text-center">
              <h5>TWILER PORTIS</h5>
              <p>IN COLLABORATION WITH ONE & DONE</p>
            </div>
          </div>
          <Link className="navbar-brand d-lg-block d-none" to="/">
            <img src={Logo2} alt="img" className="logo2" />
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default Header;
