import React from "react";

export default function Ugc() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section>
        <div className="container">
          <h2 className="ugc-header">User Generated Content Policy</h2>
          <p className="font-weight-bold">
            This User Generated Content Policy is made on 19/11/2021.
          </p>
          <p className="font-weight-bold">
            Please read this User Generated Content Policy (UGC) carefully
            before you submit it to our Website or via our Apps, as this policy
            and our Website & App Terms of Use will apply to your use of our
            Website, our Apps and the UGC you submit to it or them.
          </p>
          <p className="font-weight-bold">
            We recommend that you print a copy of this for future reference.
          </p>
          <p className="font-weight-bold">
            By using our Website or our Apps and submitting UGC to our Website
            or via our Apps, you confirm that you accept this User Generated
            Content Policy, our <ins>Privacy and Cookie Policy</ins> and our
            <ins>Website & App Terms of Use</ins>, and that you agree to comply
            with them. If you do not agree to these terms and policies, you must
            not use our Website or Apps or submit UGC to (or via) it (or them).
            Capitalised but undefined words in this User Generated Content
            Policy shall have the meanings ascribed to them in our Website & App
            Terms of Use.
          </p>
          <ol type="1">
            <li>
              <span className="font-weight-bold">Your UGC</span>
            </li>
            <ol type="1" className="ml-5">
              <li>
                All content submitted to our Website or via our Apps by you (or
                on your behalf) via your User account (or other social media
                account, if applicable), including without limitation, your
                name, bio information and all other names, usernames,
                pseudonyms, text, likenesses, graphics, logos, marks, images,
                photographs, code, and all other information and material shall
                be called your "<span className="font-weight-bold">UGC </span>"
                for short.
              </li>
              <li>
                You agree to submit UGC to the Website and via our Apps in
                accordance with the following rules (in particular, the Legal
                Standards and the Review Guidelines, as those terms are defined
                below). Please use caution and common sense when submitting UGC
                to the Website or via our App.
              </li>
              <li>
                Publication of your UGC will be at our sole discretion and we
                are entitled to make additions or deletions to your UGC prior to
                publication, after publication or to refuse publication.
              </li>
              <li>
                Please note, any UGC you submit to our Website or via our Apps
                will be considered non-confidential and non-proprietary.
              </li>
            </ol>
            <li className="pt-3">
              <span className="font-weight-bold">
                Rights, permissions & waivers
              </span>
            </li>
            <ol type="1" className="ml-5">
              <li>
                You hereby grant to Twiler Portis and any of our group companies
                and affiliates a non-exclusive, perpetual, irrevocable,
                transferable, royalty-free licence (including full rights to
                sub-license) to use, reproduce and publish your UGC (including,
                without limitation, the right to adapt, alter, amend or change
                your UGC) in any media or format (whether known now or invented
                in the future) throughout the world without restriction.
              </li>
              <li>
                You warrant, represent and undertake to us that all UGC you
                submit is your own work or that you have obtained all necessary
                rights and permissions of the relevant owner of the work and
                that you have all relevant rights in your UGC to enable you to
                grant the rights and permissions in this clause 2.
              </li>
              <li>
                Where your UGC contains images of people or names or identifies
                individuals, you warrant, represent and undertake to us as
                follows:
              </li>
              <ol type="a" className="ml-5">
                <li>
                  that all featured or identified individuals that are over the
                  age of 18 and have expressly consented to their appearance in
                  the UGC and to you submitting the UGC to our Website or via
                  our Apps, and
                </li>
                <li>
                  where featured or identified individuals are under the age of
                  18, that you either:
                </li>
                <ol type="1" className="ml-5">
                  <li>
                    are the parent or legal guardian or such featured or
                    identified individuals, or
                  </li>
                  <li>
                    have obtained the express consent from a parent or legal
                    guardian of such featured or identified individuals to their
                    appearance in the UGC and to you submitting the UGC to our
                    Website or via our Apps.
                  </li>
                </ol>
              </ol>
              <li>
                You hereby unconditionally and irrevocably waive and agree not
                to assert (or procure the same from any third party where
                applicable) any and all moral rights and any other similar
                rights and all right of publicity and privacy in any country in
                the world in connection with your UGC, to the maximum extent
                permissible by law.
              </li>
            </ol>
            <li className="mt-3">
              <span className="font-weight-bold">
                Content standards – legal standards
              </span>
            </li>
            <ol type="1" className="ml-5">
              <li>
                You warrant, represent and undertake to us that your UGC
                (including its use, publication and/or exploitation by us) shall
                not:
              </li>
              <ol type="a" className="ml-5">
                <li>
                  infringe the copyrights or database rights, trademarks, rights
                  of privacy, publicity or other intellectual property or other
                  rights of any other person or entity; and/or
                </li>
                <li>
                  contain any material which is defamatory of any person; and/or
                </li>
                <li>
                  contain misleading or deceptive statements or omissions or
                  misrepresentation as to your identity (for example, by
                  impersonating another person) or your affiliation with any
                  person or entity; and/or
                </li>
                <li>
                  breach any legal or fiduciary duty owed to a third party, such
                  as a contractual duty or a duty of confidence; and/or
                </li>
                <li>
                  advocate, promote, or assist discrimination based on race,
                  sex, religion, nationality, disability, sexual orientation or
                  age; and/or
                </li>
                <li>
                  contain any malicious code, such as viruses, worms, Trojan
                  horses or other potentially harmful programmes, codes or
                  material; and/or
                </li>
                <li>
                  violate any other applicable law, statute, ordinance, rule or
                  regulation, (together, or individually the
                  <span className="font-weight-bold ml-1">Legal Standards</span>
                  ").
                </li>
              </ol>
              <li>
                If your UGC contains any material that is not owned by or
                licensed to you and/or which is subject to third party rights,
                you are responsible for obtaining, prior to submission of your
                UGC, all releases, consents and/or licenses necessary to permit
                use and exploitation of your UGC by us without additional
                compensation. Please see clause 2 above for further details.
              </li>
            </ol>
            <li>
              <span className="font-weight-bold">
                Content standards – review guidelines
              </span>
            </li>
            <ol type="1" className="ml-5">
              <li>You warrant, represent and undertake to us that your UGC:</li>
              <ol type="a" className="ml-5">
                <li>is accurate, where it states facts; and/or</li>
                <li>
                  is genuinely held, where it states opinions (for example, in
                  product or services reviews).
                </li>
              </ol>
              <li>
                You further warrant, represent and undertake to us that your UGC
                (including its use, publication and/or exploitation by us) shall
                be compliant with our Review Guidelines, which can be found our
                Review Guidelines Policy.
              </li>
              <li>
                Any UCG which is in breach of our Review Guidelines or is
                otherwise:
              </li>
              <ol type="a" className="ml-5">
                <li>
                  is obscene, hateful, inflammatory, offensive or in any other
                  way falls below commonly accepted standards of taste and
                  decency in the UK; and/or
                </li>
                <li>
                  is reasonably likely to harass, upset, embarrass or alarm a
                  person (including, by way of example only, so called
                  "trolling" or cyber-bullying); and/or
                </li>
                <li>
                  is threatening, abusive or invades another's privacy, or
                  causes annoyance, inconvenience or anxiety; and/or
                </li>
                <li>is sexually explicit; and/or</li>
                <li>
                  advocates, promotes, assists or depicts violence; and/or
                </li>
                <li>
                  advocates, promotes or assists any illegal activity or
                  unlawful act or omission; and/or
                </li>
                <li>
                  could be deemed to be unsolicited or unauthorised advertising,
                  promotional material, junk mail, or spam (including without
                  limitation chain letters, pyramid schemes or other forms of
                  solicitation or advertisements, commercial or otherwise);
                  and/or
                </li>
                <li>
                  gives the impression that it emanates from Twiler Portis or is
                  endorsed or connected with us, if this is not the case, will
                  be removed from the Site.
                </li>
              </ol>
            </ol>
            <li>
              <span className="font-weight-bold">Consequences of breach</span>
            </li>
            <ol type="1" className="ml-5">
              <li>
                We will determine, in our discretion, whether you have failed to
                comply with this UGC Policy when submitting UGC to our Website
                or via our Apps. If you have failed to comply, we reserve the
                right in our sole discretion to suspend you from using the
                Website and/or our Apps without notice to you and/or to edit or
                remove (in whole or part) any of your UGC from our Website and
                our Apps on a temporary or permanent basis.
              </li>
              <li>
                Notwithstanding clause 5.1 above, if you or your UGC does not
                comply with this UGC Policy, and as a result of this, we suffer
                any loss or damage, you will be liable to us and hereby agree to
                indemnify us for any such loss or damage. This means that you
                will be responsible for any loss or damage we suffer as a result
                of your failure to comply with this UGC Policy, including but
                not limited to our Legal Standards and/or Review Guidelines.
              </li>
              <li>We also reserve the right:</li>
              <ol type="a" className="ml-5">
                <li>
                  to pass on any UGC that gives us concern to the relevant
                  authorities; and
                </li>
                <li>
                  to disclose your identity to any third party (or their
                  professional advisor) who claims that any of your UGC
                  constitutes a violation of their intellectual property rights,
                  or of their right to privacy.
                </li>
              </ol>
            </ol>
            <li>
              <span className="font-weight-bold">
                Changes to this UGC Policy
              </span>
            </li>
            <ol type="1" className="ml-5">
              <li>
                We may change this UGC Policy from time to time, in which case
                an up-to-date version will be available via the Website and our
                Apps. You should check this UGC Policy regularly to ensure that
                you are happy with any changes. You will be deemed to have
                accepted any changes to this UGC Policy after you have been
                notified of the changes on our Website or our Apps and/ or if
                you continue to access or use the Website or our Apps, where the
                updated UGC Policy will be available for you to view.
              </li>
            </ol>
          </ol>
          <h3 className="pt-5">Review Guidelines</h3>
          <p>When we have to step in</p>
          <p>
            We think the Twiler Portis review platform is exciting and
            inspirational. Reviews are a great way to find out everything you
            need to know. We want to keep things honest, clean and real; it
            helps if we’re all aligned.
          </p>
          <p>
            So, in the name of a healthy, positive review platform, here are our
            guidelines:
          </p>

          <h4 className="font-weight-bold">The good stuff:</h4>
          <ol type="1" className="ml-5">
            <li>
              <span className="font-weight-bold">Be Honest</span> - Whatever you
              have to say, chances are there’s someone out there who will
              benefit from your personal first-hand experiences and opinions.
              Share them all—good or bad—and include as many details as you can.
              The sooner you leave a review, the more you will remember, so
              please try and leave it as soon as you can. We’ll only publish
              reviews that are left up to 1 months after your appointment, so
              don’t lose your chance to share your views! Please note that our
              review platform is to share thoughts on the content and services
              we provide, so we only publish reviews where the appointment has
              taken place - if your app or subscription is cancelled, please let
              the Customer Experience team know and we will look into this for
              you.
            </li>
            <li>
              <span className="font-weight-bold">Be constructive. </span> If you
              have a bad experience, tell us why. Be specific about what it was
              that didn’t hit the spot and not only will other users take you
              more seriously, but the business or professional involved is much
              more likely to listen up and improve. The more detail you can
              provide in your review, the more helpful it will be to others -
              it’s fine to only use a few words, so long as they are
              constructive and helpful to other customers. If your review is
              difficult to make sense of or doesn’t help other customers, the
              content may be removed - but your star rating will still count
              towards the venues rating.
            </li>
            <li>
              <span className="font-weight-bold">Be courteous.</span> We are a
              diverse community of people with diverse opinions. Speaking to and
              about people with respect means that everyone feels free to have
              their say.
            </li>
            <li>
              <span className="font-weight-bold">
                When you see it, report it
              </span>
              . If you see abusive content or material that violates these
              guidelines, report it via the "report inappropriate content" link.
              We always try to monitor reviews that breach our guidelines but we
              definitely appreciate our customers letting us know if they spot
              something before we do. We may temporarily disable reviews where
              they have been reported so that our team can look into this
              properly.
            </li>
            <li>
              <span className="font-weight-bold">Be specific</span>. Your
              contributions are your voice on Twiler Portis, so make sure those
              smooth, dulcet tones ring out by being as clear, descriptive and
              typo-free as possible.
            </li>
            <li>
              <span className="font-weight-bold">Stay safe </span>. Be wise
              about the personal information you share when you’re using
              publicly accessible parts of the website—remember that this
              information may remain there indefinitely. If you’re not
              comfortable with this, you can post content anonymously, meaning
              that your name won’t display next to your review. Twiler Portis
              will of course still know who you are, which means we can get in
              touch if we need to. Simply go to your settings and edit your
              profile.
            </li>
          </ol>

          <h4 className="font-weight-bold">The bad stuff:</h4>
          <ol className="ml-5">
            <li>
              <span className="font-weight-bold">Stick to the facts</span>.
              Please only write reviews based on your own personal experience
              and only submit content that you know to be accurate and fair. For
              example, whilst you might not be happy with the service you
              received & may think the person treating you wasn’t qualified to
              do so, you shouldn’t write that in your review if you don’t know
              for a fact that’s the case. Your review should also only focus on
              your own treatment - please don’t write a review on behalf of
              anyone else who you see in the Twiler Portis OR Web streaming
              platform, they will have their own chance to submit a review.
            </li>
            <li>
              <span className="font-weight-bold">Keep the peace</span>. Twiler
              Portis is not the place for threats, personal attacks, aggressive
              comments or hateful, bigoted, or racist speech. We are a community
              of people with diverse beliefs, opinions, and backgrounds, so
              please be respectful.
            </li>
            <li>
              <span className="font-weight-bold">Don’t talk dirty</span>. We
              want to keep things clean, which means no swear words, no vulgar
              comments, no sexually explicit language or images and no lewd
              propositions.
            </li>
            <li>
              <span className="font-weight-bold">No spam</span>. Any obvious
              advertising references found in reviews, comments or other content
              will be deleted immediately. It is not permitted to post links,
              HTML, provide phone numbers, email addresses or to provide
              information that is unrelated to the topic in order to solicit
              personal or financial gain. It’s also forbidden to send bulk
              messages or submit any other unauthorised content with the purpose
              of promoting your business or product.
            </li>
            <li>
              <span className="font-weight-bold">No cheating</span>. Our
              reputation and credit system is there to recognize and reward
              users for writing reviews and contributing to the Twiler Portis
              community.
            </li>
            <li>
              <span className="font-weight-bold">Keeping it legal</span>. We
              don’t want to call in the police or authorities, so make sure you
              don’t break the law by:
            </li>
            <div className="d-flex flex-row pt-3 ml-5">
              <input type="checkbox" />
              <p className="ml-2 ">
                submitting fake reviews or paying someone to submit content that
                benefits your business
              </p>
            </div>
            <div className="d-flex flex-row pt-3 ml-5">
              <input type="checkbox" />
              <p className="ml-2 ">
                copying other people’s content without permission or proper
                attribution—please be aware of copyright laws
              </p>
            </div>
            <div className="d-flex flex-row pt-3 ml-5">
              <input type="checkbox" />
              <p className="ml-2 ">
                scamming people into sharing personal information
              </p>
            </div>
            <div className="d-flex flex-row pt-3 ml-5">
              <input type="checkbox" />
              <p className="ml-2 ">
                stealing or borrowing anything from TWILER PORTIS or other sites
                (like copyright or trademark material)
              </p>
            </div>
            <div className="d-flex flex-row pt-3 ml-5">
              <input type="checkbox" />
              <p className="ml-2 ">
                breaking or hacking into private unauthorised places, especially
                if it’s with the intention of causing harm or stealing
                information
              </p>
            </div>
            <div className="d-flex flex-row pt-3 ml-5">
              <input type="checkbox" />
              <p className="ml-2 ">
                threatening, defaming, making libellous statements, harassing,
                invading people’s privacy or impersonating others. Defamation or
                libel means that a review makes a false statement of fact which
                would then be damaging to the TWILER PORTIS Company.
              </p>
            </div>
          </ol>
          <p>
            We are committed to doing everything we can to protect Twiler Portis
            from fake reviews. On the rare occasion that we do find bogus
            content, spam, fake reviews or submissions from fraudulent profiles,
            we remove them immediately and take steps to ensure that the
            businesses and users in question are penalised in our rankings on
            Twiler Portis.
          </p>
          <h4 className="font-weight-bold py-5">When we have to step in:</h4>
          <p>
            Twiler Portis reserves the right to edit or remove reviews that we
            determine to be inappropriate and/or in violation of these Twiler
            Portis Review Guidelines or other terms of use.
          </p>
          <p>
            Please be aware that, depending on the level of offence, violating
            the Review Guidelines may result in the termination of your Twiler
            Portis account without warning.
          </p>
          <p>
            If your review is reported to the Review Team for breach, they may
            (a) temporarily disable the review or (b) remove its written content
            while they investigate. If they determine that the review does
            breach our Review Guidelines, they may elect to (a) remove the
            review in its entirety or (b) remove its written content whilst
            still retaining the star rating of the review. The Review Team will
            always reinstate the written content of the review if you re-edit
            and re-submit a review which conforms with these Guidelines.
          </p>
          <h5 className="font-weight-bold pb-4 pt-3">
            Serious allegations & Police Investigations
          </h5>
          <p>
            Thankfully, it is rare that any serious allegations are made against
            a service provider listed on Twiler Portis, but in the very
            unfortunate event that criminal activity, police investigations, or
            serious allegations are made against Twiler Portis, it’s partners or
            service agents, please email Twiler Portis so that we can help in
            any way that we can and can give the issue all the attention that it
            needs. We can’t allow such serious matters to be recorded on a
            review platform, so in the event that you do leave a review
            referencing serious allegations or police investigations, we will
            remove the review and reach out to you privately so that we can
            assist in the best way possible.
          </p>
          <p>
            These review guidelines were written with the best interests of the
            Twiler Portis community in mind. If you have any additional
            suggestions on how to improve them, or any other feedback, please
            send us an email -
            <a href="mailto:tlcmillionairemom@gmail.com" className="ml-1">
              tlcmillionairemom@gmail.com
            </a>
          </p>
        </div>
      </section>
    </div>
  );
}
