import React from "react";
import "./contact.css";

export default function Index() {
  return (
    <div>
      <div className="contact-header py-5">
        <h3 className="text-center text-light font-weight-bold">contact us</h3>
      </div>
      <div className="container mb-5 py-4">
        <div className="contact-section pt-5 pb-3 mt-5 ">
          <form>
            <div class="row px-4">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <input type="text" class="form-control" placeholder="Name" />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12    pt-3 pt-sm-3 pt-md-0 pt-lg-0">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Number"
                />
              </div>
            </div>
            <div class="row px-4 pt-3">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <input type="email" class="form-control " placeholder="Email" />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12  pt-3 pt-sm-3 pt-md-0 pt-lg-0">
                <input
                  type="text"
                  class="form-control "
                  placeholder="Company"
                />
              </div>
            </div>
            <div className="row px-4">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12  pt-3 pt-sm-3 pt-md-3 pt-lg-3">
                <div class="form-group">
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    placeholder="Message"
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="submit float-right pt-3">
          <button type="button" className="submit-btn ">
            SUBMIT
          </button>
        </div>
      </div>
    </div>
  );
}
