import React from "react";
import dwnImg from "../../Assets/dwn.png";
import appStore from "../../Assets/app_store.png";
import googlePlay from "../../Assets/google_play.png";

const DownloadBanner = () => {
  return (
    <div className="download_banner">
      <div className="container">
        <div className="row">
          <div className="col-md-4 text-center">
            <img src={dwnImg} alt="img" className="dwn_img" />
          </div>
          <div className="col-md-8 d-flex align-items-center">
            <div className="dwn_details">
              <h1>DOWNLOAD TWILER PORTIS APP</h1>
              <p>
                Twiler Portis is an entrepreneur, mentor, and millionaire
                networker whose story has touched and transformed the lives of
                thousands. Twiler’s entrepreneurial spirit, coupled with her
                passion for business has afforded her the unique opportunity to
                executive produce television shows, invest in business start-ups
                and mentor small businesses.
              </p>
              <p>
                Download the Twiler Portis APP to watch exclusive content, sign
                up to our Coaching, Training and Events programs. Watch live
                interviews, receive latest updates on special offers, collect
                and redeem loyalty points, buy merchandize and connect via our
                social media channels. Also purchase tickets to attend our
                amazing events and virtually network with us one-to-one all
                within the APP.
              </p>

              <div className="pb-5">
                <h4>DOWNLOAD APP</h4>
                <div className="d-flex">
                  <img
                    src={googlePlay}
                    alt="img"
                    height="50"
                    className="mr-3"
                  />
                  <img
                    src={appStore}
                    alt="img"
                    height="50"
                    style={{ border: "1px solid #9d9e9e", borderRadius: "8px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DownloadBanner;
